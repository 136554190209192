import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { ErrorSign } from "@/components/error-sign";
import { useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import ascenda_logo from "@/images/ascenda_logo.svg";
import placeholder_flag from "@/images/placeholder_flag.svg";
import { z } from "zod";
import { EmailsCard } from "@/components/emails-card";
import { Button } from "@/components/ui/button";
import { Combobox } from "@/components/combobox";
import { FormContainder } from "@/components/form-container";
import ReCAPTCHA from "react-google-recaptcha";
import { Input } from "@/components/ui/input";
import { HTTPError } from "ky";
import { trackEvent, sanitizeHTTPError } from "@/lib/utils";
import { AscendaButton } from "@/components/ascenda-button";
import { ErrorDialog } from "@/components/error-dialog";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Countries,
  Currencies,
  GenericEmailDomain,
  UrlSafeStringPattern,
} from "@/lib/constants";
import { ApiService } from "@/lib/api-service";
import { BootstrapData, CaptchaToken } from "@/lib/types";
import { SuccessPage } from "@/components/success-page";

const apiService = new ApiService();

const FormSchema = z.object({
  first_name: z
    .string({ required_error: "Please enter your first name" })
    .min(1, { message: "Please enter your first name" }),
  last_name: z
    .string({ required_error: "Please enter your last name" })
    .min(1, { message: "Please enter your last name" }),
  tenant_name: z
    .string({ required_error: "Please enter your company name" })
    .min(1, { message: "Please enter your company name" }),
  tenant_id: z
    .string({ required_error: "Please enter your desired dashboard URL" })
    .min(1, { message: "Please enter your desired dashboard URL" })
    .regex(UrlSafeStringPattern, {
      message: "Dashboard Url is an invalid url",
    }),
  country_code: z
    .string({ required_error: "Please choose your desired country" })
    .min(1, { message: "Please choose your desired country" }),
  cash_currency: z
    .string({ required_error: "Please choose your desired currency" })
    .min(1, { message: "Please choose your desired currency" }),
  email: z
    .string({ required_error: "Please enter your email address" })
    .min(1, { message: "Please enter your email address" })
    .email({ message: "Please enter a valid email address" })
    .refine(
      (data) => {
        const emailDomain = data.split("@")[1];
        return !GenericEmailDomain.includes(emailDomain);
      },
      {
        message: "Please use a company email address",
      },
    ),
});

interface AdminSchema {
  [key: string]: string;
}

interface TenantCreationParamsSchema {
  tenant_id: string;
  country_code: string;
  cash_currency: string;
  admin: AdminSchema;
}

export function TenantCreation() {
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const [showEmailList, setShowEmailList] = useState(false);
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const {
    isLoading: isBootstrapping,
    error: bootstrapError,
    data: bootstrapData,
  } = useQuery({
    queryKey: ["tenant-bootstrap"],
    queryFn: async () => {
      return await apiService.get<BootstrapData>("/api/tenants/bootstrap");
    },
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      tenant_id: "",
      email: "",
    },
  });

  const { mutateAsync: createTenant } = useMutation({
    mutationFn: (data: TenantCreationParamsSchema & CaptchaToken) => {
      return apiService.post("/api/tenants", data);
    },
  });

  async function onSubmit(formData: z.infer<typeof FormSchema>) {
    setLoading(true);

    let captcha_token = null;
    try {
      captcha_token = await recaptchaRef.current.executeAsync();
    } catch (error) {
      setLoading(false);
      setOpenDialog(true);
      recaptchaRef.current.reset();
      Sentry.captureException(error);
      return;
    }

    trackEvent("tenant_creation_submit", {
      tenant_id: formData.tenant_id,
      country_code: formData.country_code,
      cash_currency: formData.cash_currency,
    });

    const tenantCreateBody = {
      tenant_id: formData.tenant_id,
      country_code: formData.country_code,
      cash_currency: formData.cash_currency,
      tenant_name: formData.tenant_name,
      admin: {
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name,
      },
    };
    try {
      await createTenant({ ...tenantCreateBody, captcha_token });
      setServerError("");
      setLoading(false);
      navigate("/sign-up/success");
    } catch (error) {
      if (error instanceof HTTPError && error.response.status != 500) {
        const errorJson = await error.response.json();
        const errorObj = errorJson.errors[0];
        const sanitizedError = sanitizeHTTPError(errorObj);
        setServerError(sanitizedError.message);
      } else {
        setOpenDialog(true);
      }

      setLoading(false);
      recaptchaRef.current.reset();
      Sentry.captureException(error);
    }
  }

  useEffect(() => {
    if (!isBootstrapping) {
      form.setValue("email", bootstrapData?.email || "");
      form.setValue("tenant_id", bootstrapData?.tenant_id || "");
      form.setValue("tenant_name", bootstrapData?.tenant_name || "");
    }
  }, [bootstrapData, form.setValue]);

  return isBootstrapping ? null : (
    <FormContainder>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITEKEY}
      />
      <div className="self-center flex justify-center flex-1 flex-col max-lg:w-10/12 w-1/2">
        <ErrorSign
          className="my-8"
          hidden={serverError === ""}
          error_message={serverError}
        />
        <div className="w-full hidden max-lg:block mb-10">
          <img src={ascenda_logo} className="w-32" alt="" />
        </div>
        <div className="text-destructive pb-2 text-sm pt-2">SIGN UP</div>
        <div className="text-4xl font-bold text-primary pb-6 pr-6">
          Create your own rewards program now
        </div>
        <div className="text-primary pb-2">
          {`Let's start with the basics!`}
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-full max-lg:pb-10"
          >
            <FormField
              control={form.control}
              name="tenant_name"
              render={({ field }) => (
                <div>
                  <FormItem className="pb-4">
                    <FormLabel variant="secondary">COMPANY NAME</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="Central Perk Inc."
                        error={form.formState.errors.tenant_name}
                        disabled={loading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            <FormLabel className="text-lg font-bold">
              Ascenda admin dashboard
            </FormLabel>
            <FormField
              control={form.control}
              name="tenant_id"
              render={({ field }) => (
                <div>
                  <FormItem className="pb-4">
                    <div className="text-primary pb-4">
                      To make accessing your Ascenda dashboard as convinient as
                      possible, please enter your preferred dashboard URl.
                    </div>
                    <FormLabel variant="secondary">DASHBOARD URL</FormLabel>
                    <div className="flex space-x-2 relative sm:block">
                      <FormControl>
                        <Input
                          className="pr-48"
                          type="text"
                          placeholder="central-perk-inc"
                          error={form.formState.errors.tenant_id}
                          disabled={loading}
                          {...field}
                        />
                      </FormControl>
                      <span className="text-muted font-light absolute bottom-3 right-2">
                        .admin.us.ascenda.com
                      </span>
                    </div>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            <FormLabel className="text-lg font-bold">
              Rewards program locale
            </FormLabel>
            <div className="text-primary pb-2">
              {`In addition to language and pricing, we'll use these inputs to localize the content within your rewards program.`}
            </div>
            <div className="flex w-full space-x-8 max-sm:flex-col max-sm:space-x-0 max-sm:space-y-4 pb-4">
              <FormField
                control={form.control}
                name="country_code"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel variant="secondary">COUNTRY</FormLabel>
                    <Combobox
                      data={Countries.map((country) => {
                        return {
                          label: (
                            <div className="flex space-x-2 items-center">
                              <span className="text-3xl">{country.flag}</span>
                              <span>{country.name}</span>
                            </div>
                          ),
                          searchValue: country.name,
                          value: country.iso2,
                        };
                      })}
                      disabled={loading}
                      contentClassName="w-full max-sm:w-80 lg:w-80"
                      setValue={(value) => {
                        form.setValue(field.name, value, { shouldValidate: true })
                      }}
                      triggerPlaceholder={
                        <div className="flex space-x-2">
                          <img src={placeholder_flag} alt="" />
                          <span>Select</span>
                        </div>
                      }
                      searchInputPlaceholder="Search"
                      error={form.formState.errors.country_code}
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="cash_currency"
                render={({ field }) => (
                  <FormItem className="w-40 max-sm:w-full">
                    <FormLabel variant="secondary">CURRENCY</FormLabel>
                    <Combobox
                      data={Currencies.map((currency) => {
                        return {
                          label: (
                            <div className="flex space-x-4">
                              <span>{getSymbolFromCurrency(currency)}</span>
                              <span>{currency}</span>
                            </div>
                          ),
                          value: currency,
                          searchValue: currency,
                        };
                      })}
                      disabled={loading}
                      contentClassName="w-full max-sm:w-80 lg:w-40"
                      setValue={(value) => {
                        form.setValue(field.name, value, { shouldValidate: true })
                      }}
                      triggerPlaceholder="Select"
                      searchInputPlaceholder="Search"
                      error={form.formState.errors.cash_currency}
                      {...field}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormLabel className="text-lg font-bold">
              Your account details
            </FormLabel>
            <div className="text-primary pb-4">
              {`We'll use these details to set up the first user of your admin dashboard. You can add additional users via your dashboard.`}
            </div>
            <div className="flex w-full space-x-8 max-sm:space-x-0 max-sm:flex-col max-sm:space-y-4">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem className="grow">
                    <FormLabel variant="secondary">FIRST NAME</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="Ross"
                        error={form.formState.errors.first_name}
                        disabled={loading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem className="grow">
                    <FormLabel variant="secondary">LAST NAME</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="Geller"
                        error={form.formState.errors.last_name}
                        disabled={loading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="pb-4">
                  <FormLabel variant="secondary">
                    YOUR COMPANY EMAIL ADDRESS
                  </FormLabel>
                  <FormControl>
                    <Input
                      data-sentry-mask
                      type="text"
                      placeholder="ross.geller@centralperk.com"
                      error={form.formState.errors.email}
                      onFocus={() => {
                        setShowEmailList(true);
                      }}
                      disabled={loading}
                      {...field}
                      {...form.register("email", {
                        onBlur: () => {
                          setShowEmailList(false);
                        },
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                  <EmailsCard show={showEmailList} />
                </FormItem>
              )}
            />
            <AscendaButton content="Sign up" loading={loading} />
          </form>
        </Form>
      </div>
      <ErrorDialog
        title="Oh no, something went wrong..."
        content="An unexpected error has occured, sorry about that. We've been notified and will get started on a fix."
        open={openDialog}
        onOpenChange={setOpenDialog}
      />
    </FormContainder>
  );
}

export function TenantCreationSuccess() {
  return (
    <SuccessPage
      heading={`Thank you for signing up!`}
      message={`Within a few moments, you'll receive an email with instructions on how to access your new Ascenda dashboard.`}
    />
  );
}
